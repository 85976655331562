import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"; // Bootstrap Grid System
import "../styles/AboutUsPage.css"; // External CSS for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS

const AboutUsPage = () => {
  useEffect(() => {
    const aboutUsSection = document.querySelector(".aboutUsContainer");

    // Set up IntersectionObserver to trigger animation when the section is in view
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          aboutUsSection.style.animation = "slideInZoom 1.5s ease-out forwards"; // Trigger animation
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    observer.observe(aboutUsSection); // Observe the section for visibility
  }, []);

  return (
    <Container className="aboutUsPage">
      <h1 className="aboutUsHeading text-center mb-5">About Us</h1>
      
      <Row className="align-items-center aboutUsContainer">
        {/* Video Section */}
        <Col md={6} className="videoContainer">
          <video autoPlay loop muted playsInline className="aboutUsVideo" preload="auto">
            <source src="/images/Ab.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Col>

        {/* Text Section */}
        <Col md={6} className="aboutUsText">
          <p>
            We <strong>Peculiar Works</strong> is a leading digital marketing company with a strong
            focus on excellence and driving positive change. We strive to empower businesses
            and individuals through cutting-edge technology solutions. Our commitment to work
            and customer satisfaction has established us as a trusted partner in the agency.
          </p>
        </Col>
      </Row>

     {/* Our Clients Section */}
<section id="ourClients" className="clientsSection">
  <Container fluid className="text-white py-5">
    <h2 className="clientsHeading text-center">Our Clients</h2>
    <Row className="justify-content-center">
      <Col xs={12} md={10}>
        <div className="logoWrapper">
          {/* Client Logos */}
          <Col xs={6} sm={4} md={4} className="d-flex justify-content-center mb-3">
            <img src="/images/jslogo.png" alt="Client Logo" className="clientLogo" />
          </Col>
          <Col xs={6} sm={4} md={4} className="d-flex justify-content-center mb-3">
            <img src="/images/logo2.jpg" alt="Client Logo" className="clientLogo" />
          </Col>
          <Col xs={6} sm={4} md={4} className="d-flex justify-content-center mb-3">
            <img src="/images/Cb-logo.jpg" alt="Client Logo" className="clientLogo" />
          </Col>
        </div>
      </Col>
    </Row>
  </Container>
</section>


     {/* Our Influencers Section */}
<section id="ourInfluencers" className="influencersSection mt-5">
  <Container>
    <h2 className="influencersHeading mb-4">OUR INFLUENCERS</h2>
    <Row className="justify-content-center">
      <Col xs={12} sm={6} md={4} className="text-center">
        <div className="influencerCard">
          <img src="/images/singh.jpg" alt="Deepika Singh" className="influencerImage" />
          <h5 className="mt-3">Deepika Singh</h5>
        </div>
      </Col>
    </Row>
  </Container>
</section>

    </Container>
  );
};

export default AboutUsPage;
